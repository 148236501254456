import { CustomLink } from '@components/CustomLink'
import { getRedirectLink } from '@features/home/utils'
import BlockOutlined from '@material-ui/icons/BlockOutlined'
import { CampaignData } from '@store/modules/home/types'
import { removeAccents } from '@utils/common'
import * as Styled from './styles'

type DepartmentProps = {
    department: CampaignData | undefined
}

export const DepartmentView = ({
    department
}: DepartmentProps): JSX.Element => {
    const getRenderedImage = (): JSX.Element => {
        if (department && department.imagem.url)
            return (
                <Styled.ImageContent
                    src={department.imagem.url}
                    alt={department.imagem.descricao}
                    layout="fill"
                    objectFit="cover"
                />
            )

        return <BlockOutlined />
    }

    return department ? (
        <Styled.Container>
            <CustomLink
                idName={'btn_depart' + department.nome.replace(/\s/g, '')}
                target="_parent"
                href={getRedirectLink(
                    department.acaoClick,
                    removeAccents(department.link)
                )}
            >
                <Styled.GridIcon justifyContent="center" alignItems="center">
                    {getRenderedImage()}
                </Styled.GridIcon>
                <Styled.Title>{department.nome}</Styled.Title>
            </CustomLink>
        </Styled.Container>
    ) : (
        <></>
    )
}
