import styled from 'styled-components'
import { CustomImage } from '@components/CustomImage'
import { Typography } from '@components/ui/Typography'
import { Stack } from '@components/Stack'

export const Container = styled(Stack)`
    align-self: baseline;
    a:hover {
        text-decoration: none;
    }
`

export const GridIcon = styled(Stack)`
    color: ${({ theme }) => theme.colors.gray[700]};
    width: 80px;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    margin: 0 12px 15px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary[500]};
`

export const ImageContent = styled(CustomImage)`
    border-radius: 50%;
`

export const Title = styled(Typography).attrs(({ theme }) => ({
    fontFamily: theme.isDesktop ? 'regular' : 'medium',
    size: theme.isDesktop ? 'base' : 'xs'
}))`
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: ${({ theme }) => (theme.isDesktop ? 'nowrap' : 'auto')};
    word-wrap: ${({ theme }) => (theme.isDesktop ? 'auto' : 'break-word')};
`
